// Generated by Framer (05fbd34)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {aI6iKWdCx: {hover: true, pressed: true}};

const cycleOrder = ["aI6iKWdCx"];

const serializationHash = "framer-WUBsl"

const variantClassNames = {aI6iKWdCx: "framer-v-1828ntj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, BKq9MYJr0: link ?? props.BKq9MYJr0} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BKq9MYJr0, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "aI6iKWdCx", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Link href={BKq9MYJr0}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1828ntj", className, classNames)} framer-3g4pff`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aI6iKWdCx"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} {...addPropertyOverrides({"aI6iKWdCx-hover": {"data-framer-name": undefined}, "aI6iKWdCx-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-9ihl0c-container"} layoutDependency={layoutDependency} layoutId={"GteVryZe4-container"} style={{rotate: 1}}><Phosphor color={"var(--token-86409dd1-fb45-4d6d-b59b-36291556e364, rgb(24, 23, 28)) /* {\"name\":\"Black\"} */"} height={"100%"} iconSearch={"circle arrow"} iconSelection={"ArrowCircleRight"} id={"GteVryZe4"} layoutId={"GteVryZe4"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({"aI6iKWdCx-hover": {color: "var(--token-1739f382-f211-4b06-944b-0298215b9005, rgb(237, 32, 36)) /* {\"name\":\"Brand Primary\"} */"}, "aI6iKWdCx-pressed": {color: "rgb(99, 199, 77)", iconSelection: "CheckCircle"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-WUBsl[data-border=\"true\"]::after, .framer-WUBsl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WUBsl.framer-3g4pff, .framer-WUBsl .framer-3g4pff { display: block; }", ".framer-WUBsl.framer-1828ntj { cursor: pointer; height: 200px; position: relative; text-decoration: none; width: 200px; }", ".framer-WUBsl .framer-9ihl0c-container { bottom: -25px; flex: none; left: -104px; position: absolute; right: -105px; top: -26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"I60zAzaIu":{"layout":["fixed","fixed"]},"mCqemzxSz":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"BKq9MYJr0":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerhag_AXRlw: React.ComponentType<Props> = withCSS(Component, css, "framer-WUBsl") as typeof Component;
export default Framerhag_AXRlw;

Framerhag_AXRlw.displayName = "Button for Course Card v2";

Framerhag_AXRlw.defaultProps = {height: 200, width: 200};

addPropertyControls(Framerhag_AXRlw, {BKq9MYJr0: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerhag_AXRlw, [...PhosphorFonts])